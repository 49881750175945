// export function apiURL() {
//   if (!navigator.onLine) {
//     return "http://127.0.0.1:42427";
//   } else {
//     return "http://127.0.0.1:42427";
//   }
// }

export const SOCKET_ENDPOINT = "https://hms-socket-server.herokuapp.com";

// // deploy setup
export function apiURL() {
  // if (!navigator.onLine) {
  //   return "https://yge.wvi.mybluehost.me/test/sanda-server";
  // } else {
  // return "http://127.0.0.1:49496";
  return "https://yge.wvi.mybluehost.me/test/joli_market";
  // }
}

export const mylikitaURL = "https://mylikita.herokuapp.com";
// export const mylikitaURL = 'http://localhost:9000';

// export const twilioServer = 'http://localhost:6000';
export const twilioServer = "https://bits-video-server.herokuapp.com";
